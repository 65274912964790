
import './App.css';
import ReactTypingEffect from 'react-typing-effect';

function App() {
  return (
    <div className="Content">
        <div className="Home-image" style={{backgroundColor:'black'}}>
          <div className="image-left">
          <h3>We connect your business with the <br/>
            <ReactTypingEffect
              text={["F-U-T-U-R-E","D-R-E-A-M"]}
              cursorRenderer={cursor => <b>{cursor}</b>}
              displayTextRenderer={(text, i) => {
                return (
                  <b>
                    {text.split('-').map((char, i) => {
                      const key = `${i}`;
                      return (
                        <span
                          key={key}
                          style={i % 2 === 0 ? { color: '#61dafb' } : { color: '#61dafb' }}
                        >{char}</span>
                      );
                    })}
                  </b>
                );
              }}
            />
         </h3>
          </div>
          <div className="image-right"></div>


        </div>
        <div className="Home-section-wd" id="wd-center">
          <div>
            <p>What Would You Like us to do For You?</p>
          
          </div>
        </div>
        <div className="Home-section-wd">
          <div className="Home-section_l">

            <h3>SOFTWARE DEVELOPMENT</h3>
            <p>Our team of sofware development is commited to bring to you best apps with minimal design posible.</p>
            <h5 style={{textAlign:'right',color:'#05637d',fontWeight:'800',fontSize:'1rem'}}>Mobile Apps</h5>
              <h5 style={{textAlign:'right',color:'#05637d',fontWeight:'800',fontSize:'1rem'}}>Web Apps</h5>
              <h5 style={{textAlign:'right',color:'#05637d',fontWeight:'800',fontSize:'1rem'}}>API</h5>
          </div>
          <div className="Home-section_r">
            <h3>AUTOMATIONS</h3>
            <p>We help you automate your business process, hence increase efficiency and productivity.</p>
            <h5 style={{textAlign:'left',color:'#05637d',fontWeight:'800',fontSize:'1rem'}}>Integrations</h5>
            <h5 style={{textAlign:'left',color:'#05637d',fontWeight:'800',fontSize:'1rem'}}>Reconciliations</h5>
          </div>
        </div>
        <div className="Home-section-wd">
          <div className="Home-section_l">
            <h3>DATA ANALYTICS</h3>
            <p>With the use of data analytics tools, we help analyzing raw data in order to make informed decisions for the future if your business.</p>
          </div>
          <div className="Home-section_r">
            <h3>SOFWARE CONSULTATION</h3>
            <p>Best results are inspired by good process flows. 
              Having perfect software for your business makes you achieve this many times more.</p>
          </div>
        </div>
        
    </div>
  );
}

export default App;
