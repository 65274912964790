import logo from './wdSmTrns.png';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';

function Header() {
  return (
    <div className="Site-header">
      <div className="Site-header-left">
      <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/">
        <img src={logo} className="Site-logo" alt="logo" />
        </NavLink>
      </div>
      <div className="Site-header-right">
        <ul className="Header-menu" style={{ paddingRight: '30px' }}>
          <li>
            <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/about">About</NavLink>
          </li>
          <li>
            <NavLink exact={true} activeClassName='is-active' className="nav-link" to="/">Home</NavLink>
          </li>
          <li className="menu-links">
            +255 769 003 815
            </li>
          <li className="menu-links">
            salesupport@waydata.co.tz
            </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
