function About() {
  return (
    <div className="content" style={{marginTop:'7rem'}} >
        <h3 className="page-title">
          About Us
        </h3>
        <div className="about-content">
        <div className="about-left">
          In 2017 - 2018 we did a research on how businesses operates in Tanzania and most of African countries. We came to realize that most of them operates with shortage of information and experience hardship in monitoring and evaluation of profit and los. 
          <br/>
          <br/>
          This affects their productivity big time.
          We tried to make a pilot project(Software) for SACCOS and VICOBA and after seeing its value to the society, we have been adding some features to make it a product for massive consumption, including MICROFINACE Institutions.
          <br/>And now it it is going with the name <a style={{textDecoration:'none'}} href ="https://mfukopesa.com" target="_blank">MfukoPesa</a>.
          <p>
          We also do software delevopment for other businesses when we get contacted by our esteemed clients.
        </p>
        </div>
        <div className="about-right">
          <div className="about-right-span">
            <div className="item"></div> <b style={{marginLeft:'1rem'}}>Productivity</b>
          </div><br/>
          <div className="about-right-span">
          <div className="item"></div>
          <b style={{marginLeft:'1.8rem'}}>Efficiency</b>
          </div><br/>
          <div className="about-right-span">
          <div className="item"></div>
          <b style={{marginLeft:'1.8rem'}}>Creativity</b>
            </div>
        </div>
        
</div>
<div className="about-content">
<div className="about-right-down">
          <div className="about-right-span">
            <div className="item"></div> <b style={{marginLeft:'1.8rem'}}>SOFTWARE DEVELOPMENT</b>
          </div>
          <div className="about-right-span">
          <div className="item"></div>
          <b style={{marginLeft:'1.8rem'}}>AUTOMATIONS</b>
          </div>
          <div className="about-right-span">
            <div className="item"></div>
            <b style={{marginLeft:'1.8rem'}}>DATA ANALYTICS</b>
          </div>
          <div className="about-right-span">
            <div className="item"></div>
            <b style={{marginLeft:'1.8rem'}}>SOFWARE CONSULTATION</b>
          </div>
        </div>
        <div className="about-left">
         
        </div>
       
        
</div>
        
        
    </div>
  );
}

export default About;
