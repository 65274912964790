import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';
// import logo from './smTrans.png';
import logo from './wdSmTrns.png';

function Footer() {
  let newDate = new Date();
  let _date = newDate.getFullYear();
  return (
    <div style={{ fontSize: '12px' }}>
      <div className="Footer">
        <div className="Footer-section">
          <p>
          </p>
          <NavLink exact={true} activeClassName='is-active' className="nav-link" style={{textDecoration:'none',textDecorationColor:'none'}} to="/">
            <img src={logo} className="Footer-logo" alt="logo" />
            </NavLink>
        </div>
        <div className="Footer-section">
          <b>CONTACTS</b>
          <p>
            WayData Company Ltd<br />
            Hekima Building<br />
            Kinondoni Leaders<br />
            Dar es salaam, Tanzania<br /><br /><br />

            <b>Send Us Mail</b> <br />salesupport@waydata.co.tz <br /><br />
            Call Us: +255 769 003 815 <br />
          </p>
        </div>
        <div className="Footer-section">
          <b>RESOURCES</b>
          <p><NavLink exact={true} activeClassName='is-active' className="nav-link" to="/about">About</NavLink></p>
          <p><NavLink exact={true} activeClassName='is-active' className="nav-link" to="/">Home</NavLink></p>
        </div>
      </div>
      <div style={{ textAlign: 'center', backgroundColor: '#05637d', color: 'white', padding: '1rem', backgroundColor: '#013f50' }}>
        © {_date} WayData. All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
